import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import StarIcon from '@mui/icons-material/StarBorder';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import {Divider} from '@mui/material';
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import "../styles/global.css"
import Nav from './nav'
import BackgroundImage from 'gatsby-background-image';
import Img from "gatsby-image"

const Layout1 = ({ pageTitle, children, dynamicTitle }) => {
  const footers = [
  {
    title: 'Company',
    description: [{title:'About',route:'/about'}, {title:'Contact us',route:'/contact'}],
  },
  {
    title: 'Resources',
    description: [{title:'Pier Specs',route:'/about'}],
  },
  {
    title: 'Legal',
    description: [{title:'Privacy policy',route:'/about'}],
  },
];

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        BearTooth CC 
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

  const data = useStaticQuery(graphql`
      query {
         file(relativePath: { eq: "BTCC.webp" }) {
          childImageSharp {
          # Specify a fixed image and fragment.
          # The default width is 400 pixels
          fluid(quality:100, maxWidth: 800, maxHeight: 500, fit: INSIDE) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      placeholderImage: file(relativePath: { eq: "pile.png" }) {
          childImageSharp {
          # Specify a fixed image and fragment.
          # The default width is 400 pixels
          fixed(quality:100, height:300, fit:INSIDE) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      placeholderImage1: file(relativePath: { eq: "pile.png" }) {
          childImageSharp {
          # Specify a fixed image and fragment.
          # The default width is 400 pixels
          fluid(quality:100, maxWidth: 800, maxHeight: 500, fit: INSIDE ) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      site {
        siteMetadata {
          title
        }
      }
  }
` )
  const image = data.file?.childImageSharp?.fluid

  return (
    <>
    <React.Fragment>
      <GlobalStyles styles={{ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      <CssBaseline />
      <Nav image={image} />
      {/* Hero unit */}
      <Container align="center"  disableGutters component="div" sx={{margin:0, width:"100%", height:"100%", "max-width":"100% !important" }}> 
        {children}
      </Container>
      {/* End hero unit */}
      {/* Footer */}
      <Container
        minWidth="500px"
        component="footer"
        sx={{
          backgroundColor:"rgba(101, 125, 126, 1)",
          borderTop: (theme) => `1px solid ${theme.palette.divider}`,
          mx:[0,0],
          "max-width":"100% !important",
          py: [3, 6],
        }}
      >
        <Grid  container spacing={2} justifyContent="space-around">
        </Grid>
        <Copyright sx={{ mt: 5 }} />
      </Container>
     
      {/* End footer */}
    </React.Fragment>
    {/* <div className={container}>
      <Nav pageTitle={pageTitle} />
      <main>
        <h1 className={heading}>{pageTitle}</h1>
        {children}
      </main>
    </div> */}
    </>
  )
}
export default Layout1