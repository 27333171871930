import * as React from 'react';
import { useEffect, useState } from "react"
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import "../styles/global.css"
import Layout from '../components/layout1'
import BackgroundImage from 'gatsby-background-image'
import Img from "gatsby-image"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import {TextField, ButtonGroup} from '@mui/material';
import { useForm, ValidationError } from '@formspree/react';
import Video from "../components/video";
import { SEO } from "../components/seo"
function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const settings = {
  dots: true,
  speed: 500,
  infinite: false,
  slidesToShow: 4,
  pauseOnHover: true,
  slidesToScroll: 0,
  initialSlide: 0,
  focusOnSelect: true,
       responsive: [
        {
          breakpoint: 1920,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 2
          }
        },
        {
          breakpoint: 920,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
}

const multiSettings = {
      className: "center",
      centerMode: true,
      infinite: false,
      centerPadding: "60px",
      slidesToShow: 2,
      speed: 500,
      rows: 1,
      slidesPerRow: 2
  };

const tiers = [
  {
    title: 'Free',
    price: '0',
    description: [
      '10 users included',
      '2 GB of storage',
      'Help center access',
      'Email support',
    ],
    buttonText: 'Sign up for free',
    buttonVariant: 'outlined',
  },
  {
    title: 'Pro',
    subheader: 'Most popular',
    price: '15',
    description: [
      '20 users included',
      '10 GB of storage',
      'Help center access',
      'Priority email support',
    ],
    buttonText: 'Get started',
    buttonVariant: 'contained',
  },
  {
    title: 'Enterprise',
    price: '30',
    description: [
      '50 users included',
      '30 GB of storage',
      'Help center access',
      'Phone & email support',
    ],
    buttonText: 'Contact us',
    buttonVariant: 'outlined',
  },
];

const footers = [
  {
    title: 'Company',
    description: ['Team', 'History', 'Contact us', 'Locations'],
  },
  {
    title: 'Features',
    description: [
      'Cool stuff',
      'Random feature',
      'Team feature',
      'Developer stuff',
      'Another one',
    ],
  },
  {
    title: 'Resources',
    description: ['Resource', 'Resource name', 'Another resource', 'Final resource'],
  },
  {
    title: 'Legal',
    description: ['Privacy policy', 'Terms of use'],
  },
];

function Index() { 

function animate() {
  var navi = document.getElementById("hm");
  var navi2 = document.getElementById("quote");
  if (!(navi||navi2)){
    return
  }
  const viewportHeight = window.innerHeight;
  const scrollTop = window.scrollY;
  const elementOffsetTop = navi.offsetTop;
  const elementHeight = navi.offsetHeight;
  const element2Height = navi2.offsetHeight;
  const distance = scrollTop + viewportHeight - elementOffsetTop;
  const percentage = Math.round(
    distance / ((viewportHeight + elementHeight) / 100)
  );
  let r = Math.round((elementHeight/scrollTop)*100-100 );
     navi.style.maxWidth = `${r>100 ? "100" :r<0 ? "0":r}%`;

    let l = Math.round((element2Height/scrollTop)*100-100 );
      navi2.style.maxWidth = `${l>100 ? "100" :l<0 ? "0":l}%`;
}
const [offset, setOffset] = useState(100)
const [toggle, setToggle] = useState(false)
const [service, setService] = useState(false)
const isBrowser = typeof window !== "undefined";

useEffect(() => {
  if (isBrowser) {
  if (typeof window !== `undefined`) {
        var navi = document.getElementById("hm");
        var navi2 = document.getElementById("quote");
    window.onscroll = () => {
      animate()
    }
  }
}
}, [isBrowser]);

  const data = useStaticQuery(graphql`
    query {
    servicesImages:allFile(
    filter: {extension: {regex: "/(jpg)|(jpeg)|(png)|(webp)/"}, relativeDirectory: {eq: "services"}}
    ) {
    edges {
      node {
        id
        name
        childrenImageSharp {
           fluid(maxWidth:270, maxHeight:200) {
            ...GatsbyImageSharpFluid
          }
        }
        childrenImageSharp {
           fixed(width:270, height:200) {
            ...GatsbyImageSharpFixed
          }
        }

      }
    }
  }
      placeholderImage: file(relativePath: { eq: "beartoohMTN.webp" }) {
        childImageSharp {
          # Specify a fixed image and fragment.
          # The default width is 400 pixels
          fluid(quality: 90, maxWidth: 1920,srcSetBreakpoints: [ 400, 600, 960, 1280, 1920 ]){
            ...GatsbyImageSharpFluid
          }
        }
      }
      site {
        siteMetadata {
          title
        }
      }
       allMdx(filter: {fileAbsolutePath: {regex: "/services/"}}) {
    edges {
      node {
        id
        slug
        frontmatter {
          name
          title
          subhead
          subhead2
          par_1
          par_2
          slides {
            childImageSharp {
              gatsbyImageData
            }
          }
          subProduct {
						name
            par_1
            par_2
            par_3
            subhead
            subhead2
            videoSrcURL
          }
          videoSrcURL
          hero_image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
}
  `)

  const image = getImage(data.allMdx.edges[0].node.frontmatter.hero_image)
  const subServiceImages = (data.allMdx.edges.map((edge,index)=>{
    if(edge.node.frontmatter?.slides){
      return edge.node.frontmatter.slides.map(slide=>getImage(slide))
    }
    return
  })).filter(Boolean)[0]

  const subVideo = (data.allMdx.edges.map((edge,index)=>{
      let videoArray=[]
    if(edge.node.frontmatter?.videoSrcURL){
      videoArray.push(edge.node.frontmatter.videoSrcURL)
    }
    if(edge.node.frontmatter?.subProduct?.videoSrcURL){
      videoArray.push(edge.node.frontmatter.subProduct.videoSrcURL)
    }

    return videoArray.length>0?videoArray:null
  })).filter(Boolean)[0]

  const HeroImage_MTN = data?.placeholderImage?.childImageSharp?.fluid
  const servicesImgs = data?.servicesImages?.edges.map(imgs=>{
    return {img:imgs.node.childrenImageSharp[0].fixed, name:imgs.node.name, imgFluid:imgs.node.childrenImageSharp[0].fluid}
  })
  
const [applicationsMDX, setApplicationsMDX] = useState(false)
const [dynamicExtraInfo, setDynamicExtraInfo] = useState(false)
const [dynamicImg, setDynamicImg] = useState(false)

  useEffect(() => {
    if (service){
    setApplicationsMDX((data.allMdx.edges.filter(images=>images.node.slug.match(`services/${service.name}/${service.name}`)))[0])
    }
    
}, [service])

 const [state, handleSubmit] = useForm("mlevqnve");
 const [showExtraMedia, setShowExtraMedia] = useState('Images');
 const [showExtraMediaGroup, setShowExtraMediaGroup] = useState(false);
 const [showSubProduct, setShowSubProduct] = useState();

  useEffect(() => {
    if ((dynamicExtraInfo?.slides||[]).length || dynamicExtraInfo?.videoSrcURL){
      setShowExtraMediaGroup(!showExtraMediaGroup)
    }
}, [toggle])

  let p = isBrowser ? document.documentElement.clientHeight-95 : 800
  let top = isBrowser ? document.documentElement.clientTop+p:0

return (
  <Layout>
    <Grid>
      {/* START BACKGROUND AND SLIDER START */}
      <Grid>
        <Grid id="backGroundImage" backgroundColor={"black"}>
          <Grid>
      <Grid  backgroundColor="rgb(0,0,0)" sx={{width: "100%",
      height:`${top}px`,
    display: "flex",
    position:"absolute",
    "justify-content": "center",
    opacity:0.4}}>
      </Grid>
       <Grid sx={{
    height: "100%",
    width: "100%"}}>
        <Typography component="h3" variant='h3'  alignSelf="center" color="white" fontWeight="bold" fontSize={60} zIndex={99} sx={{    height: "100%",
    display: "flex",
    "align-items": "center",
    position: "absolute",
    width: "100%",
    "justify-content": "center"}}>
        Welcome to BearTooth Construction, <br /> piers above the rest          
        </Typography>
        <Grid container justifyContent={"space-between"} data-sal-repeat data-sal="slide-left" sx={{position:"absolute", bottom:"1px", height:"7em"}}>
          <Grid  id="hm" lg={6} xs={6} item sx={{  'z-index':99,backgroundColor:"rgba(0,0,0,.6)", "align-items": "center", "justify-content": "flex-end", "display": "flex"}} >
            <Grid marginRight="1em">
            <Typography textAlign="end" sx={{color:"white", fontSize:"24px"}}>
              {/* <a href="#services">Services</a> */}
              <Link href="#services" underline="none" color="white">Services</Link>
            </Typography>
            </Grid>
        </Grid>
        <Grid id="quote" lg={6} xs={6} item alignItems="center" sx={{ 'z-index':99, backgroundColor:"rgba(0,0,0,.6)",  "align-items": "center", "justify-content": "flex-start", "display": "flex"}}>
          <Grid marginLeft="1em">
            <Typography component="p" textAlign="start" variant='p' sx={{color:"white",fontSize:"24px"}}>
              {/* <a href="#quote">Quote</a> */}
              <Link href="#quote2" underline="none" color="white">Quote</Link>

            </Typography>
            </Grid>
        </Grid>
      </Grid>
      </Grid>
          </Grid>
          <Grid id="bbb" sx={{opacity: ".6", height: `${p}px`, backgroundColor:"white"}}>
            <Img
              placeholder="blurred"
              style={{
                minWidth:"500px",
                height: `${p}px`,
                width: "100%",
              }}
              Tag="section"
              loading='eager'
              fluid={HeroImage_MTN}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent={"center"} fullWidth sx={{minWidth:"100%"}}>
         <Typography id="services" component="h6" variant='h3' pt=".5em" pb=".5em">Services</Typography>
        </Grid>
        {/* START TO THE SLIDER START */}
        <Grid sx={{width:"90%"}}>
          <Grid  >
          <Slider {...settings} > 
            {servicesImgs.map(serviceImg=>(
              <Grid onClick={(event)=>{event.preventDefault()
                    setToggle(!toggle)
                    setService(serviceImg.name !== service.name ? serviceImg : false)
                    let nodes = (data.allMdx.edges.filter(images=>{
                      return images.node.slug.match(`/${serviceImg.name}/`)
                    }))[0]
                    setShowSubProduct(nodes?.node?.frontmatter?.subProduct?.name ? nodes.node.frontmatter.name : false)
                    setApplicationsMDX(nodes)
                    setDynamicExtraInfo(nodes?.node.frontmatter);
                    setDynamicImg(getImage(nodes?.node?.frontmatter?.hero_image))
                  // navigate(`/applications/${serviceImg.name}`)
                }}>
                <Img key={serviceImg.name} fixed={serviceImg.img} style={{"border-radius":"10%", backgroundColor:"gray", "min-width":"200px"}}/>
                <Typography component="h6" variant='h6'>{serviceImg.name.replace(/_+/g," ")}</Typography>
              </Grid>
            ))}             {/* <Typography component="h6" variant='h6'>{servicesImgs[1].name.replace(/_+/g," ")}</Typography> */}
          </Slider>
          </Grid>
        </Grid>
        {/* END TO THE SLIDER END */}

        {/* START if the slider image is clicked START */}
        <Grid>
        {!!service && !!dynamicImg &&
            <>
          <Typography component="h6" className={"sal-animate"} variant='h3' pt=".5em" pb=".5em">{dynamicExtraInfo.name}{dynamicExtraInfo?.subProduct ? " / " + dynamicExtraInfo?.subProduct?.name : null}</Typography>
          {!!dynamicExtraInfo?.subProduct && <>  <ButtonGroup  variant="text" style={{paddingBottom:"1em"}} onClick={(event)=>{
                event.preventDefault()
                setShowSubProduct(event.target.textContent)
              }}>
                <Button variant={showSubProduct===dynamicExtraInfo.name?"contained":"outlined"}>{dynamicExtraInfo.name}</Button>
                <Button variant={showSubProduct===dynamicExtraInfo.subProduct.name?"contained":"outlined"}>{dynamicExtraInfo?.subProduct.name}</Button>
              </ButtonGroup>
              </>
              }
          <Grid container justifyContent="space-evenly" pb=".6em" sx={{backgroundColor:"#fafbf9"}}> 
            <Box sx={{backgroundColor:"#fafbf9", width:"40%",display: "flex",
            flexDirection: "column",
            height: "100%",
            alignItems: "center"
            }}>
              <Typography paddingBottom={"1em"} variant="h5">{dynamicImg.images.fallback.src.match(/([^:\\/]*?)(?:\.([^ :\\/.]*))?$/)[1].charAt(0).toUpperCase() + dynamicImg.images.fallback.src.match(/([^:\\/]*?)(?:\.([^ :\\/.]*))?$/)[1].substring(1)}</Typography>
            <GatsbyImage placeholder="blurred" imgStyle={{objectFit: `contain`}} image={dynamicImg} style={{width:'19em', height:'16em'}}/>
            {(!!dynamicExtraInfo?.videoSrcURL || (dynamicExtraInfo.slides||[]).length>0) && <Grid md={10} container justifyContent={"space-evenly"} paddingTop={"10px"}  style={{
                      border: "black",
                    borderWidth: "medium",
                  borderStyle: "solid",
                  marginTop: "1em"
                }}>
              <ButtonGroup  variant="text" onClick={(event)=>{
                event.preventDefault()
                // console.log('event', event.target.textContent)
                setShowExtraMedia(event.target.textContent)
              }}>
                <Button variant={showExtraMedia==="Images"?"contained":"outlined"}>Images</Button>
                <Button variant={showExtraMedia==="Videos"?"contained":"outlined"}>Videos</Button>
              </ButtonGroup>

            
                <Grid id="images" container md={10} justifyContent={"space-around"} paddingTop={"10px"} paddingBottom={"10px"}>
                  {showExtraMedia==="Images" 
                  && (dynamicExtraInfo.slides||[]).length>0 
                  && <Grid sx={{width:"100%"}}><Slider {...multiSettings}>
                  {(Array.isArray(subServiceImages)  ? subServiceImages:[subServiceImages]).map((serviceImg, index)=>(
                       <>
                       <Grid style={{padding:".3em"}} onClick={(event)=>{event.preventDefault()
                    setDynamicImg({...serviceImg, video:subVideo})
                  }} >
                      <GatsbyImage  placeholder="blurred" image={serviceImg} style={{
                      border: "black",
                    borderWidth: "thin",
                  borderStyle: "solid",
                  "border-radius":"10%",
                  "max-height": "5.5em",
                  backgroundColor:"gray", 
                  }} />
                  </Grid>
                  </>
                  ))}
                  
                    </Slider>
                    </Grid>
                  }
                {showExtraMedia==="Videos" &&  <Grid container fullWidth id="videos">
                  {subVideo.map((item,i) => <Grid container sx={{"paddingBottom": "1em"}}><Video key={i} width="90" height="200" videoSrcURL={item} placeholder="blurred" /></Grid>)}
                   </Grid>}
                </Grid>

            </Grid>}
          </Box>  
            <Grid container xs={5} item sx={{"flexDirection": "row","alignContent": "start"}}>
            <Grid container item>
              <Typography width="100%" pt="1%" textAlign="center" variant='h5'>{(dynamicExtraInfo.name === showSubProduct || !showSubProduct)?dynamicExtraInfo.subhead:dynamicExtraInfo?.subProduct?.subhead}</Typography>
              <Typography paddingTop={"1em"}>{(dynamicExtraInfo.name === showSubProduct || !showSubProduct)?dynamicExtraInfo.par_1:dynamicExtraInfo?.subProduct?.par_1}</Typography>
              <Typography paddingTop={"1em"}>{(dynamicExtraInfo.name === showSubProduct || !showSubProduct)?dynamicExtraInfo.par_3:dynamicExtraInfo?.subProduct?.par_3}</Typography>
            </Grid>

            <Grid container lg={12} item pt="2em">
              <Typography width="100%" pt="5%" textAlign="center" variant='h5'>{(dynamicExtraInfo.name === showSubProduct || !showSubProduct)?dynamicExtraInfo.subhead2:dynamicExtraInfo?.subProduct?.subhead2}</Typography>
              <Typography paddingTop={"1em"}>{(dynamicExtraInfo.name === showSubProduct || !showSubProduct)?dynamicExtraInfo.par_2:dynamicExtraInfo?.subProduct?.par_2}</Typography>
            </Grid>
              {dynamicExtraInfo?.subProduct?.videoSrcURL && showSubProduct===dynamicExtraInfo?.subProduct?.name && <Grid container lg={12} item pt="2em" minHeight="15em" justifyContent={"center"}>
                <Video videoSrcURL={dynamicExtraInfo.subProduct.videoSrcURL} placeholder="blurred" />
              </Grid>
              }
            {dynamicExtraInfo.videoSrcURL && showSubProduct===dynamicExtraInfo.name && <Grid container lg={12} item pt="2em" minHeight="15em" justifyContent={"center"}>
                <Video videoSrcURL={dynamicExtraInfo.videoSrcURL} placeholder="blurred" />
              </Grid>
              }
            </Grid>

          </Grid>
          </>
            }
        </Grid>
        {/* END if the slider image is clicked END */}
      </Grid>
      {/* END BACKGROUND AND SLIDER END */}
      {/* START "WHY WE ARE GREAT" START */}
      <Grid container backgroundColor="rgba(221, 221, 221, .6)" padding="1em" justifyContent={"center"}>
        <Typography component="h6" variant='h5' pt=".5em" pb=".5em">
          What separates us?
        </Typography>
        <Grid container justifyContent={"center"}>
          <Grid justifyContent={"center"} container item md={12} sm={6} xs={6}>
            <Grid container justifyContent={"center"}>
              <Typography component="h6" variant='h6' style={{"fontWeight":"bold"}}>Trust</Typography>
            </Grid>
              <Typography component="p" variant='inherit' fontSize={"12px"} pb="1em">Insured and bonded, to protect you and your property. Local, Not a "fly by night" company</Typography>
          </Grid> 
          <Grid justifyContent={"center"} item container md={12} sm={6} xs={6}>
            <Grid container justifyContent={"center"}>
              <Typography component="h6" variant='h6' style={{"fontWeight":"bold"}}>Knowledge</Typography>
            </Grid>
            <Typography component="p" variant='inherit' fontSize={"12px"} pb="1em">We have over 50 years of experience...</Typography>
          </Grid>      
          <Grid justifyContent={"center"} item container md={12} sm={6} xs={6}>
            <Grid container justifyContent={"center"}>
              <Typography component="h6" variant='h6' style={{"fontWeight":"bold"}}>Care</Typography>
            </Grid>
            <Typography component="p" variant='inherit' fontSize={"12px"} pb="1em" >We are willing to go that extra mile to finish the job, not leaving you with yard/house to put back together</Typography>
          </Grid>  
          <Grid justifyContent={"center"} item container md={12} sm={6} xs={6}>
            <Grid container justifyContent={"center"}>
              <Typography component="h6" variant='h6' style={{"fontWeight":"bold"}}>Service</Typography>
            </Grid>
            <Typography component="ul" variant='inherit' fontSize={"12px"} pb="1em">
              <li>
            <Typography component="p" variant='inherit' fontSize={"12px"} pb="1em">We do it all, from the ground (or lower if talking about our GoliathTech Piers) up and more</Typography>
              </li>
               <li>
           <Typography component="p" variant='inherit' fontSize={"12px"} pb="1em">We offer financing for all your needs</Typography>
              </li>
            </Typography>
          </Grid>      
 
          <Grid justifyContent={"center"} item container md={12} sm={6} xs={6}>
            <Grid container justifyContent={"center"}>
              <Typography component="h6" variant='h6' style={{"fontWeight":"bold"}}>Pier Service</Typography>
            </Grid>
            <Typography component="ul" variant='inherit' fontSize={"12px"} pb="1em">
              <li>
                Decks
              </li>
              <li>
                Commercial Applications
              </li>
              <li>
                Mobile Homes
              </li>
              <li>
                Home Foundations
              </li>
              <li>
                Swimming Pools
              </li>
              <li>
                Sheds
              </li>
              <li>
                Underpinning
              </li>
            </Typography>
          </Grid>
        </Grid>  
      </Grid>
      {/* END "WHY WE ARE GREAT" END */}
      {/* START "HOW CAN WE HELP" START */}
      <Grid
        item
        sm={12} xs={12} md={6}
        id="form"
        sx={{
          marginTop: 5,
          pb:5,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
         }}
      >
        <Box component="form" id="quote2" noValidate  sx={{ px:"1em", width:"80%",  backgroundColor:"grey", minWidth:"500px"}} >
          <Typography component="h6" variant='h3'>
            How can we help?
          </Typography>
          <Typography component="h6" variant="subtitle1" pb=".5em">
            Ask us about financing 
          </Typography>
          <form  action="https://formsp  ree.io/f/mlevqnve" method="post">
            <Grid container  spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  color="primary"
                  id="firstName"
                  label="First Name"
                  // autoFocus
                  sx={{backgroundColor:"white"}}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  sx={{backgroundColor:"white"}}
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  type="email"
                  sx={{backgroundColor:"white"}}
                  autoComplete="email"
                />
                 <ValidationError 
                  prefix="Email" 
                  field="email"
                  errors={state.errors}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField 
                multiline 
                sx={{backgroundColor:"white"}}  
                fullWidth 
                rows={4} 
                name="description"
                placeholder='Description'
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2,  backgroundColor:'rgba(237, 181, 0, 1)', color:"black" }}
            >
              send
            </Button>
          </form>
          <Grid container justifyContent="flex-end">
          </Grid>
        </Box>
      </Grid>
      {/* END HOW CAN WE HELP END */}
    </Grid>
  </Layout>)
}

export default function TestHidden() {
  return <Index />;
}

export const Head = () => (
  <SEO />
)