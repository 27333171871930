import React, {useState} from "react"
import CircularProgress from '@mui/material/CircularProgress';
import CardMedia from '@mui/material/CardMedia';
const Video = ({ videoSrcURL, videoTitle, key="video", width="560", height="315", sx={}, ...props }) => {
  const [loading, setLoading] = useState(true)
  const [show, setShow] = useState(false)

let hideSpinner = () => {
  setLoading(false)
  setShow(true)
  };

return (
  <div className="video" key={key}  style={{width: "100%"}}>
    {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/qJYk1Wm1S0Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
    <CardMedia
        component='iframe'
        width={width}
        height={height}
        // sx={{height:"100%" }}
        src={videoSrcURL}
        title={videoTitle}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"        alt="Extra Videos"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
      />    
  </div>
)}

export default Video