import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import StarIcon from '@mui/icons-material/StarBorder';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
// import Link from '@mui/material/Link';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import "../styles/global.css"
import { Link } from "gatsby"
import Divider from '@mui/material/Divider';
import Img from "gatsby-image"

const Nav = ({ pageTitle, children, image }) => {
return (
<AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}`, backgroundColor:"rgba(101, 125, 126, 1)", minWidth:"500px" }}
      >
        <Toolbar sx={{ flexWrap: 'wrap' }}>
            <Grid  sx={{width:"10em", paddingTop:".5em", paddingBottom:".5em"}}>
              <Img loading='eager' fluid={image}></Img>
            </Grid>
          <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
            {pageTitle}
          </Typography>
          <nav>
          <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
            Contact: (406)-200-8327
          </Typography>

              {/* <Link
              // variant="button"
              // color="text.primary"
              to="/"
              sx={{ my: 1, mx: 1.5 }}
            >
            <Button>
            Home
            </Button>
            </Link> */}
             {/* <Divider orientation="vertical" flexItem /> */}
            {/* <Link
              // variant="button"
              // color="text.primary"
              to="/contact"
              sx={{ my: 1, mx: 1.5 }}
            >
            <Button>
              Contact
            </Button>

            </Link> */}
             {/* <Divider orientation="vertical" /> */}
            {/* <Link
              // variant="button"
              // color="text.primary"
              to="/about"
              sx={{ my: 1, mx: 1.5 }}
            >
              <Button>
              About
              </Button>
            </Link> */}
          </nav>
        </Toolbar>
      </AppBar> 
)
}
export default Nav